<template>
  <Layout>
    <PageHeader title="Create Invoice" />
    <div class="row p-4 flex justify-center">
      <div class="col-md-10 p-4 bg-light">
        <div class="form-group">
          <label for="Custommer-number">Customer Internal Number</label>
          <input type="text" placeholder="enter custommer internal Number" class="form-control"
            v-model="clientinternalnumber" />
        </div>

        <!-- Dynamic inputs -->
        <div class="form-group">
          <label for="Customer Type"> Select Customer Type</label>
          <select v-model="clienttypeid" class="form-control" name="customer type">
            <option value="1">Oonpay customer</option>
            <option value="2">None Oonpay customer</option>
          </select>
        </div>
        <div v-if="clienttypeid == 2">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="First Name">Customer First Name</label>
                <input type="name" placeholder="enter first name" class="form-control" v-model="clientfirstname" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="Last Name">Customer Last Name</label>
                <input type="name" placeholder="enter last name" class="form-control" v-model="clientlastname" />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="customer number">Click to Verify your number</label>
                <input style="background-color: #d3d3d3; text-align:left;" type="button"
                  placeholder="enter phone number" class="form-control" data-toggle="modal" v-model="clientphonenum"
                  data-target="#businesPhonModalCenter" />
              </div>

              <!-- Modal -->
              <div class="modal fade" id="businesPhonModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="businesPhonModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content pb-5">
                    <div class="modal-header">
                      <h5 class="modal-title" id="businesPhonModalLongTitle">
                        Verify Customer Phone
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body space-y-5">
                      <div v-if="show_confirm == 1">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label for="countries">Select country code</label>
                              <select v-model="selected_code" class="form-control" name="countries">
                                <option v-for="country in countries_code" :key="country.index" :value="country">
                                  {{ country.callCode }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <input type="number" v-model="customerphonenumber" placeholder="enter phone number"
                                class="form-control" />
                            </div>

                            <button @click.prevent="sendCode" class="btn btn-block btn-primary">
                              send code
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="show_confirm == 2">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <input type="text" placeholder="xxxxxx" class="form-control" v-model="confirm_otp" />
                            </div>
                            <button @click.prevent="verifyCode" class="btn btn-block btn-primary">
                              verify
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="customer email">Customer Email</label>
                <input type="name" placeholder="enter email address" class="form-control" v-model="clientemail" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="clienttypeid == 1" class="form-group">
          <div class="form-group">
            <label for="">Select Country code</label>
            <div class="form-row">
              <div class="col-sm-3">
                <select class="form-control" v-model="clientcountrycode">
                  <option v-for="(code, key) in countries_code" :value="code" :key="key">{{ code.callCode }}</option>
                </select>
              </div>
              <div class="col-md-9">
                <input v-model="clientephone" type="text" placeholder="Enter phone number" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- Multi select -->
        <div class="form-group">
          <label for="Service">Service(s) Offered</label>
          <multiselect v-model="value" :options="options" :multiple="true" :clear-on-select="false"
            :hide-selected="true" placeholder="Select service(s)" label="fullName" track-by="fullName"></multiselect>
        </div>

        <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Start Date"> Services Start Data</label>
              <input type="date" name="start data" class="form-control" placeholder="choose start date"
                v-model="servicestartdate" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="Start Date"> Services End Data</label>
              <input type="date" name="end data" class="form-control" placeholder="choose end date"
                v-model="serviceenddate" />
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Total Cost">Total Cost </label>
              <input type="number" class="form-control" placeholder="enter total cost" v-model="totalcost" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="payment due">Select Payment Due Date</label>
              <input type="date" name="data" class="form-control" placeholder="enter payment due date"
                v-model="duedate" />
            </div>
          </div>
        </div>

        <div class="form-group mt-4">
          <div class="custom-file">
            <input type="file" @change="uploadFile" class="custom-file-input" multiple accept="application/pdf,.csv"
              ref="files" />
            <label class="custom-file-label" for="upload docs">upload documents here</label>
            <div v-for="(file, key) in Documents" :key="key"
              class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3">
              <div class="d-flex justify-content-between">
                <span> {{ file.name }}</span>
                <span @click="deleteUpdatedDoc(key)" class="text-oonpaysecondary"><i class="fa fa-trash"
                    aria-hidden="true"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group pb-4">
          <label for="Customer Phone Number"></label>
          <div class="form-row">
            <div class="col-sm-3">
              <select v-model="selected_benefactor_callCode" class="form-control" name="countries">
                <option v-for="country in countries_code" :key="country.index" :value="country">
                  {{ country.callCode }}
                </option>
              </select>
            </div>
            <div class="col-sm-6">
              <input type="number" v-model="benefactor" class="form-control" placeholder="enter benefactory phone" />
            </div>
            <div class="col-md-3">
              <button @click.prevent="getBenefactor" class="btn btn-primary">
                Add
              </button>
            </div>
          </div>
          <div v-if="phonenumbers.length > 0" class="row flex mt-3 justify-center">
            <div class="col-md-6">
              <ul v-for="(num, key) in phonenumbers" :key="key" class="list-group">
                <li class="list-group-item d-flex justify-between  ">

                  <span> {{ num.firstName + " " + num.lastName }} ({{ num.phoneNumber }})</span>
                  <span @click="deleteNumber(key)"><span class="iconify" data-icon="fluent:delete-dismiss-28-filled"
                      data-inline="false"></span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button @click.prevent="handleSubmitInvoice" class="btn my-4 btn-primary btn-sm btn-block">
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>Create Invoice</span>
        </button>
      </div>
    </div>
    <modal @close="toggleModal" :modalActive="modalActive">
      <div class="modal-content">
        <p class="text-center text-danger">{{ errorMessage }}</p>
      </div>
    </modal>
  </Layout>
</template>

<script>
import Multiselect from "vue-multiselect";
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import $ from "jquery";
import Modal from "../../Global/Modal.vue";
export default {
  components: { Layout, Multiselect, PageHeader, Modal },
  data() {
    return {
      clientinternalnumber: "",
      clientfirstname: "",
      clientlastname: "",
      clientemail: "",
      clienttypeid: "",
      clientcountryId: "",
      clientephone: "",

      servicestartdate: "",
      serviceenddate: "",
      totalcost: "",
      duedate: "",
      benefactors: [],
      Documents: [],
      ServicesOffered: [],
      selected_callCode: "",
      confirm_otp: null,
      show_confirm: 1,
      options: [],
      value: [],
      clientcountrycode: "",

      phonenumbers: [],
      benefactorData:[],
      clientphonenum: "",
      countries_code: [],
      phone: "",
      files: null,
      benefactor: "",
      benefactorsGuid: [],
      selected_benefactor_callCode: "",

      selected_code: null,
      customerphonenumber: null,

      customer_type: "",
      GUID: "",
      timeStamp: "",
      loading: false,

      merchantguid: window.localStorage.getItem("merchantGUID"),
      employeeGUID: window.localStorage.getItem("employeeGUID"),
      modalActive: false,
      errorMessage: ""
    };
  },
  methods: {
    // Get Invoice Required data
    GetInvoiceRequiredData() {
      const data = {
        id: this.merchantguid
      };
      this.$store
        .dispatch("invoice/GetInvoiceRequiredData", data)
        .then(({ data }) => {
          this.options = data.item2;
          // console.log(data.item1);
          this.countries_code = data.item1;
        });
    },
    toggleModal() {
      this.modalActive = false;
    },
    //Send OTP
    sendCode() {
      let newPhone = this.customerphonenumber;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var phone = newPhone.substring(1);
      }

      let main_number = `${this.selected_code.callCode}${phone}`;

      const data = {
        code: this.selected_code.callCode,
        phonenumber: main_number
      };
      this.$store
        .dispatch("invoice/VerifyCustomerPhone", data)
        .then((response) => {
          if (response.status == 200) {
            this.show_confirm = 2;
            this.otp = response.data;
            this.customerphonenumber = "";
          }
          // console.log(response);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data, {
            duration: 5000
          });
        });
      this.$store.dispatch("invoice/CustomerPhoneNum", main_number);
    },

    // Verify Phone code

    verifyCode() {
      this.$store
        .dispatch("healthhub/verifyOTP", this.confirm_otp)
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            localStorage.setItem("newcusphone", response.data);
            this.$store.dispatch("pharmacy/SetNonOonpayCustGUID", response.data)
            $("#businesPhonModalCenter").modal("hide");
            this.show_confirm = 1;
            this.confirm_otp = "";
            this.clientphonenum = this.$store.state.invoice.customerphonenumber;
            this.$toasted.success("phone number confirmed successfully", {
              duration: 5000
            });
          }
        })
        .catch(() => {
          this.$toasted.error("invalid number please enter valid number", {
            duration: 5000
          });
        });
    },

    // Benefactor
    getBenefactor() {
      let newPhone = this.benefactor;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var benefactornumber = newPhone.substring(1);
      }
      let main_number = `${
        this.selected_benefactor_callCode.callCode
      }${benefactornumber || newPhone}`;
      const data = {
        phonenumber: main_number
      };
      this.$store
        .dispatch("invoice/GetBenefactor", data)
        .then((response) => {
          if (response.status == 200) {
            console.log(response)

            this.phonenumbers.push(response.data);
            this.benefactorsGuid.push({ clientGUID: response.data.guid });
            this.benefactorData.push(response.data)
            this.benefactor = "";
          } else {
            this.modalActive = true;
            this.errorMessage = "Sorry Your number is not on Oonpay";
          }
        })
        .catch(() => {
          this.modalActive = true;
          this.errorMessage = "Your number is not on Oonpay";
        });
    },

    deleteNumber(key) {
      const index = this.phonenumbers.indexOf(key);
      this.phonenumbers.splice(index, 1);

      // console.log(this.phonenumbers);
    },

    uploadFile(e) {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.Documents.push(this.$refs.files.files[i]);

        // console.log(this.Documents);
      }
    },
    deleteUpdatedDoc(key) {
      this.Documents.splice(key, 1);
    },

    handleSubmitInvoice() {
      //client phone number
      console.log(this.value)
      let phone = this.clientephone;
      if (phone.charAt(0) == 0 || phone.charAt(0) == 1) {
        var phonenum = phone.substring(1);
      }
      let clientephone = `${this.clientcountryId ||
        this.clientcountrycode.callCode}${phonenum}`;
      let countryId = this.clientcountrycode.id || this.selected_code.id;

      //get services offered guid array
      //console.log(this.value)
      const services_guid = this.value.map((item) => {
        const container = {
          serviceid: item.id,
          ServiceFullName: item.fullName
        };

        return container;
      });

      //get benefactor numbers
      let benefactor = this.benefactorData.map((item) => {
        const num = {
          clientGUID: item.guid,
          ClientPhoneNumber: item.phoneNumber
        };

        return num;
      });

      const formData = new FormData();
      formData.append("clienttypeid", this.clienttypeid);
      formData.append("clientinternalnumber", this.clientinternalnumber);
      formData.append("ClientPhoneNumber", this.clientphonenum || clientephone),
        formData.append("clientfirstname", this.clientfirstname);
      formData.append("clientlastname", this.clientlastname);
      formData.append("clientemail", this.clientemail);
      formData.append("clientcountryId", countryId);
      formData.append("EmployeeGUID", this.employeeGUID);
      formData.append("GUID", this.GUID);
      formData.append("timeStamp", this.timeStamp);
      formData.append("ServiceStartDate", this.servicestartdate);
      formData.append("ServiceEndDate", this.serviceenddate);
      formData.append("TotalCost", this.totalcost);
      formData.append("DueDate", this.duedate);
      formData.append("benefactors", JSON.stringify(benefactor));
      formData.append("ServicesOffered", JSON.stringify(services_guid));

      var self = this;
      for (let i = 0; i < this.Documents.length; i++) {
        let file = self.Documents[i];
        formData.append("Documents", file);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.loading = true
      this.$store
        .dispatch("invoice/CreateInvoice", formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.$router.push("/invoices");
            this.$toasted.success("Invoice created successfully", {
              duration: 5000
            });
          }
        })
        .catch((err) => {
          this.loading = false
          this.modalActive = true;
          err.response.data.map((res) => {
            this.errorMessage = res.error;
          });
        });
    }
  },
  mounted() {
    this.GetInvoiceRequiredData();
  }
};
</script>

<style scoped>
.iconify {
  color: red;
}
</style>
